@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

.blind {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    font-size: 0;
    line-height: 0;
}

html, body, h1, h2, h3, h4, h5, a, .btn_type, .ui.modal>.header {
    font-family: NanumSquare, sans-serif !important;
}

table, th, td {
    font-family: NanumSquareRound, sans-serif !important;
}

body {
    overflow: auto;
    min-width: 1920px;
    height: auto;
    margin: 0 auto;
    padding: 20px 0 50px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

#root {
    width: 1440px;
    margin: 0 auto;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 15px;
}

.ui.celled.table {
    th {
        text-align: center;
    }

    tr {
        td:first-child {
            border-left: 1px solid rgba(34, 36, 38, .1);
        }
    }

    td {
        text-align: right;
    }

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4) {
        text-align: center;
    }

    td:nth-child(3) {
        text-align: left;
    }

    tfoot td {
        border-top: 1px solid rgba(0, 0, 0, 0.2)
    }
}

.ui.celled.table.tbl_type.v1 {
    td {
        text-align: right
    }

    td:nth-child(1) {
        text-align: center;
    }
}

.ui.celled.table.tbl_type.v2 {
    tbody {
        td {
            text-align: center !important;
        }
    }
}

.ui.celled.table.tbl_type.v4 {
    letter-spacing: -1px;
    margin-bottom: 20px;

    tbody {
        td {
            text-align: center;
        }

        td:first-child {
            text-align: left;

            .txt {
                display: block;
                overflow: hidden;
                max-width: 100px;
                text-overflow: ellipsis;
            }
        }

        td.td_type {
            .txt {
                width: 100px;
            }

            text-align: left;
        }

        td.td_type2 {
            background: #f3f3f3;
        }
    }

    tfoot {
        td {
            text-align: center;
        }
    }
}

.btn_type {
    border: 0;
    background: none;
    color: #1a7ecb;
    // font-weight: bold;
    cursor: pointer;
}

.btn_type:hover,
.btn_type:focus {
    text-decoration: underline;
}

.btn_type.v1 {
    font-family: NanumSquareRound;
    font-weight: normal;
    text-align: left;
    letter-spacing: -1px;
}

.btn_type.v2:hover,
.btn_type.v2:focus {
    color:#333;
    text-decoration:none;
}

.bx {
    display: flex;
}

.bx2 {
    h2 {
        margin: 0;
    }
}

.bx3,
.bx3 .buttons {
    width: 745px;
}

.bx3 .buttons {
    margin-bottom: 10px;
}

.bx3 {
    .ui.table {
        font-size: 11px;

        .txt {
            display: block;
            overflow: hidden;
            max-width: 33px;
            text-overflow: ellipsis;
        }
    }
}

.bx4 {
    margin-bottom: 30px
}

.ui.vertical.menu {
    z-index: 100;
    position: fixed;
    left: 20px;
    top: 20px;
    padding: 0;
    text-align: left;
    font-size: 12px;
}

.ui.vertical.menu {
    list-style: none;

    ul {
        list-style: none;
        padding-inline-start: 20px;
    }

    >li:first-child {
        >button {
            border: 0 !important
        }
    }

    li.item {
        padding: 0
    }

    li.item:before {
        background: none
    }

    button {
        border: 0;
        border-top: 1px solid #e7e7e7e7 !important
    }

    button:hover {
        cursor: pointer
    }

    .item {
        display: block;
        width: 100%;
        text-align: left
    }

    .active {
        font-weight: bold !important
    }
}

.txt {
    display: block;
    overflow: hidden;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.menu.v1 {
    display: flex;
    list-style: none;
    margin: 0 0 30px;
    padding: 0;

    li {
        flex: 1;
        padding: 0;

        button {
            display: block;
            width: 100%;
            height: 100%;
            border: 0;
            font-size: 13px;
            text-align: center;
        }

        button.active {
            font-weight: bold;
        }

        button:hover {
            cursor: pointer;
        }
    }
}

.ui.menu:not(.vertical) .item {
    display: block;
}

.ui.celled.table {
    border-collapse: collapse;
}

.ui.celled.table thead tr td:first-child,
.ui.celled.table thead tr th:first-child {
    border: 1px solid rgba(34, 36, 38, .1)
}

.idx_low,
.idx_mid,
.idx_high {
    color: #fff
}

.idx_low {
    background: #FF4E42;
}

.idx_mid {
    background: #FFA400;
}

.idx_high {
    background: #0CCE6B;
}

.modals {
    .ui.table {
        margin: 0 0 20px !important;
        font-size: 13px !important;

        thead {
            tr {
                th {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background: #f9f9f9
                }

                &:first-child {
                    th {
                        border-top: 0
                    }
                }

                th {
                    background: #f9f9f9;
                    text-align: center
                }

                td {
                    border-left: 1px solid rgba(34, 36, 38, .1);
                    text-align: center;

                    &:nth-child(2) {
                        text-align: left
                    }

                    pre {
                        font-family: 나눔고딕
                    }
                }

                td.tc {
                    text-align: center
                }

                td.tl {
                    text-align: left
                }
            }
        }

        tfoot {
            td {
                border-top: 2px solid #b1b1b1 !important;
                text-align: center !important;
            }
        }
    }

    .ui.table td {
        padding: 6px 5px;
        line-break: break-word
    }

    .ui.table.v1 {
        width: 552px;

        tr.active {
            td {
                background: #f6f6f6;
                font-weight: bold
            }
        }

        th {
            padding: 0.2em !important
        }
    }

    .ui.celled.table.v2 {
        border-left: 0;

        tr {

            th:first-child,
            td:first-child {
                border-left: 1px solid rgba(34, 36, 38, .1)
            }
        }
    }

    .ui.celled.table.v3 {
        td{
            text-align: center !important;
        }
    }

    .svg-inline--fa.fa-w-10 {
        width: 0.5em !important
    }

    .ui.menu {
        margin: 1rem 0 2rem !important;

        .active.item {
            font-weight: bold !important
        }
    }

    .ui.vertical.menu {
        position: absolute !important
    }

    .buttons {
        float: left;
        width: 552px;
        margin-bottom: 10px !important;

        .ui.button {
            padding: 8px 0 7px !important;
            font-size: 12px !important
        }

    }

    .tbl2 {
        display: none;
        max-width: 378px;
        border-collapse: collapse
    }

    .tbl2 th,
    .tbl2 td {
        padding: 10px 0 9px;
        border: 1px solid #999;
        text-align: center;
        font-size: 11px
    }

    .tbl2 th {
        background: #e9e9e9
    }

    .tbl2 tfoot td {
        font-weight: bold
    }

    .tbl2 caption {
        padding: 7px 0 6px;
        text-align: left;
        font-size: 14px;
        font-weight: bold
    }

    .tbl2 td a:hover span {
        color: #3376b8
    }

    .tbl_type4-6 {
        width: 1320px;
        max-width: none;
        margin: 0 auto;
    }

    .tbl_type4-6 caption {
        background: #fff
    }

    .tbl_type4-6 td {
        background: #fff
    }

    .tbl_type4-6 .tx {
        padding-left: 10px;
        text-align: left
    }

    .tbl_type4-6 tfoot td {
        background: #999
    }

    .tbl_type4-6 button {
        display: inline-block;
        margin-left: 4px;
        color: #3376b8;
        font-weight: bold;
    }

    .ui.dimmer {
        position: fixed !important;
    }

    .bx {
        clear: both;
    }

    .bx2 {
        float: left;
        width: 768px;
    }

    .bx3 {
        float: left;
    }

    .bx4 {
        overflow: auto;
        float: left;
        height: 410px;
        margin-bottom: 10px;
    }

    .bx5 {
        float: left;
        text-align: center;

        p {
            em {
                font-size: 11px;
                font-style: normal;
            }
        }
    }

    .bx5.v1 {
        width: 75px;
        height: 90px;
    }

    .bx5.v2 {
        float: none;
        width: 120px;
        height: 100px;
        margin: 0 auto !important;
    }

    .bx6 {
        overflow: hidden;
        width: 75px;
        margin: 0 auto;

        >.bx5:first-child {
            margin-right: 5px;
        }
    }

    .bx6.v1 {
        width: 155px;
    }

    .bx7 {
        margin-bottom: 20px;
    }

    .txt_type {
        display: block;
        width: 80px;
        margin: -46px 0 26px
    }

    .bg_holiday {
        background: #f3f3f3;
    }

    .h_type {
        position: relative;

        .date {
            display: block
        }
    }

    .fc {
        clear: both;
        margin: 20px 0;
    }

    .fc-sat {
        color: #1a73e8;
    }

    .fc-sun {
        color: #ed1c24;
    }

    .fc h2 {
        text-align: center
    }

    .btnGrp {
        width: 100px;
        margin: -2px 8px 0 0 !important;
    }

    .txt {
        font-size: 12px;

        span {
            &:before {
                display: inline;
                content: ', ';
            }

            &:first-child {
                &:before {
                    content: '';
                }
            }
        }
    }

    .ui.small.progress .bar.error {
        border-color: #f00 !important;
    }


    @import url('./circle.css');

    .c100 {
        font-size: 75px;

        >span {
            color: #666;
            transition-duration: 0s;
            transition-timing-function: unset;
            transition-property: unset;
        }
    }

    .c100.v1 {
        font-size: 100px
    }

    .c100 .bar.error,
    .c100 .fill.error {
        border-color: #f00 !important;
    }

    .c100 .bar.caution,
    .c100 .fill.caution {
        border-color: #f86300 !important;
    }

    .c100:hover>span {
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
    }

    .c100:hover:after {
        top: 0.08em;
        left: 0.08em;
        width: 0.84em;
        height: 0.84em;
    }

    caption {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
    }

    .search {
        width: 500px;
        margin-top: 10px;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        background-color: white;
        background-image: url('https://www.w3schools.com/howto/searchicon.png');
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 12px 20px 12px 40px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
    }

    .search:focus {
        width: 700px;
    }

    .search_area {
        position: relative;
        z-index: 100;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
    }

    .ui.vertical.menu.v1 {
        position: absolute !important;
        left: 415px;
        top: 40px;
        width: 500px;
    }

    .ui.vertical.menu.v1:focus {}

    .date {
        display: none;
        padding-left: 10px;
        color: #777;
        font-size: 16px;
    }

    .dsc {
        display: none;
        visibility: hidden;
        margin-top: 50px;
        font-size: 20px;
    }

    .btn_print {
        position: absolute;
        right: 45px;
        top: 6px;
        display: inline-block;
        padding: 6px;
        border: 0;
        font-size: 14px;
        font-weight: 400;
    }

    .btn_print:hover {
        cursor: pointer;
    }

    .print {
        align-items: normal !important;
        flex-direction: row !important;
        .btn_print,
        .actions {
            display: none
        }

        .scrolling {
            max-height: none !important;
            overflow: none !important;
        }
    }

    h3 {
        color: rgba(0, 0, 0, .87)
    }

    .txt_danger {
        color: #f00
    }

    .modal{
        width:1180px;
    }

    .modals.v1 {
        .modal {
            width: 1330px;

            .actions {
                text-align: right
            }
        }
    }
}

.modals.v1 {
    .modal {
        width: 1370px;
        padding: 0 20px;

        .actions {
            text-align: right
        }
    }
}

.ui.modal>.close {
    background-color: none;
    font-size:1.6em
}

.ui.modal>.btn_type2 {
    position:absolute;
    right:10px;
    top:12px;
}

.ui.fullscreen.modal>.close{
    right: -30px;
    top: -40px;
    width:1.18em;
    height:1em;
    color:#fff;
    font-size: 1.6em;
}

.btn_close {
    position: absolute;
    right: 0;
    top: 0;
}

.bx.scroll {
    overflow: auto;
    max-height: 252px;
}

.ui.buttons:not(.basic):not(.inverted)>.button,
.ui.buttons>.ui.button:not(.basic):not(.inverted) {
    background: none
}

.ui.buttons .button {
    background: #eaeaea !important
}

.ui.buttons .button.active {
    background: #cfcfcf !important
}

.ui.icon.input{
    margin:18px 0 -10px;
    input{
        width:400px;
    }
}